/* You can add global styles to this file, and also import other style files */
@import "theme.scss";
@include mat-core();
@include angular-material-theme($app-theme);

:root {
    --primary: #000fa0;
    --secondary: #2C2C2C;
    --secondary-dark: #131313;
    --primary-hover: rgba(0, 16, 160, 0.575);
    --primary-dark: #f37a23;
    --secondary-light: #ffffff;
    --rgba-primary-1: rgba(13, 153, 255, 0.1);
    --rgba-primary-2: rgba(13, 153, 255, 0.2);
    --rgba-primary-3: rgba(13, 153, 255, 0.3);
    --rgba-primary-4: rgba(13, 153, 255, 0.4);
    --rgba-primary-5: rgba(13, 153, 255, 0.5);
    --rgba-primary-6: rgba(13, 153, 255, 0.6);
    --rgba-primary-7: rgba(13, 153, 255, 0.7);
    --rgba-primary-8: rgba(13, 153, 255, 0.8);
    --rgba-primary-9: rgba(13, 153, 255, 0.9);
    --rgba-secondary-1: rgba(44, 44, 44, 0.1);
    --rgba-secondary-2: rgba(44, 44, 44, 0.2);
    --rgba-secondary-3: rgba(44, 44, 44, 0.3);
    --rgba-secondary-4: rgba(44, 44, 44, 0.4);
    --rgba-secondary-5: rgba(44, 44, 44, 0.5);
    --rgba-secondary-6: rgba(44, 44, 44, 0.6);
    --rgba-secondary-7: rgba(44, 44, 44, 0.7);
    --rgba-secondary-8: rgba(44, 44, 44, 0.8);
    --rgba-secondary-9: rgba(44, 44, 44, 0.9);
    --title: #374557;
    --icon-color: #737B8B;
    --warn-color: #f44336;
}

* {
    font-family: 'Roboto', sans-serif;
}

.page-titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem 1.95rem 0.2rem;
    width: 100%;
    background: #ffffff;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #E6E6E6;
    flex-flow: wrap;
}

.example-full-width {
    width: 100%;
}

thead tr {
    background-color: #F0F4F9 !important;
}

.example-container {
    margin-bottom: 10px !important;
    max-height: calc(100vh - 300px) !important;

    .mat-table {
        height: 100% !important;
        overflow: auto !important;
    }


    .mat-paginator {
        bottom: 0px;
        position: sticky;
        z-index: 10;
    }
}


// Custom Scrollbar 

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
}

.ck .ck-powered-by {
    display: none !important;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
}